import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'
import app from '../store/modules/app'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

const demoRoutes = []

export default new Router({
  // mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...demoRoutes,
    // {
    //   path: '/',
    //   redirect: { name: 'register' },
    // },
    {
      name: 'register',
      path: '/',
      component: () => import('../components/pages/Register.vue'),
      // default: true,
    },
    {
      name: 'login-page',
      path: '/login',
      component: () => import('../components/pages/Login.vue'),
      default: true,
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          beforeEnter: (to, from, next) => {
            getPage('Dashboard')
            next()
          },
          component: () => import('../components/dashboard/Dashboard.vue'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'registration',
          path: 'registration-process',
          beforeEnter: (to, from, next) => {
            getPage('Student Registration')
            next()
          },
          component: () => import('../components/pages/Registration/registrationForm'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'view-students',
          path: 'students-registered',
          beforeEnter: (to, from, next) => {
            getPage('Students Registered')
            next()
          },
          component: () => import('../components/pages/view-students'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'intakes',
          path: 'available-intakes',
          beforeEnter: (to, from, next) => {
            getPage('Intakes')
            next()
          },
          component: () => import('../components/pages/Intakes/Intakes'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'faculty',
          path: 'faculty',
          beforeEnter: (to, from, next) => {
            getPage('Faculty Page')
            next()
          },
          component: () => import('../components/pages/Faculty/Faculty'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'department',
          path: 'department',
          beforeEnter: (to, from, next) => {
            getPage('Departments')
            next()
          },
          component: () => import('../components/pages/Departments/Department'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'class',
          path: 'class',
          beforeEnter: (to, from, next) => {
            getPage('Class Page')
            next()
          },
          component: () => import('../components/pages/Classes/Class'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'communication',
          path: 'communication-portal',
          beforeEnter: (to, from, next) => {
            getPage('Communication Portal')
            next()
          },
          component: () => import('../components/pages/Communication/Communication'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'courses',
          path: 'course',
          beforeEnter: (to, from, next) => {
            getPage('Courses')
            next()
          },
          component: () => import('../components/pages/Courses/Courses'),
          meta: { requiresAuth: true },
        },
        {
          name: 'course-records',
          path: 'course-records',
          beforeEnter: (to, from, next) => {
            getPage('Course Records')
            next()
          },
          component: () => import('../components/pages/Courses/course-records'),
          meta: { requiresAuth: true },
        },
        {
          name: 'settings',
          path: 'school-settings',
          beforeEnter: (to, from, next) => {
            getPage('Settings Page')
            next()
          },
          component: () => import('../components/pages/Settings'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'privilege',
          path: 'features-roles',
          beforeEnter: (to, from, next) => {
            getPage('Privileges And Roles')
            next()
          },
          component: () => import('../components/pages/Privileges/Groups'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          path: '/student-info/:student_id',
          name: 'single-student',
          beforeEnter: (to, from, next) => {
            getPage('Student Information')
            next()
          },
          meta: { requiresAuth: true },
          component: () => import('../components/pages/SingleStudent'),
        },
        {
          path: '/student-card',
          name: 'studentCard',
          beforeEnter: (to, from, next) => {
            getPage('Student card')
            next()
          },
          meta: { requiresAuth: true },
          component: () => import('../components/pages/StudentCard'),
        },
        {
          name: 'staff',
          path: 'staff',
          beforeEnter: (to, from, next) => {
            getPage('Staff Page')
            next()
          },
          component: () => import('../components/pages/Staff/Staff'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'levels',
          path: 'levels',
          beforeEnter: (to, from, next) => {
            getPage('Levels')
            next()
          },
          component: () => import('../components/pages/levels/Levels'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'marks',
          path: 'marks-entry',
          beforeEnter: (to, from, next) => {
            getPage('Marks Registration')
            next()
          },
          component: () => import('../components/pages/Marks/marks'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'posts',
          path: 'posts',
          beforeEnter: (to, from, next) => {
            getPage('Staff Posts')
            next()
          },
          component: () => import('../components/pages/Posts/Posts'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'document-setup',
          path: 'document-settings',
          beforeEnter: (to, from, next) => {
            getPage('Document Settings')
            next()
          },
          component: () => import('../components/pages/Documents/DocumentSettingsPage'),
          default: true,
          meta: {
            requiresAuth: true,
            disabled: true,
          },
        },
        {
          path: '/students/:class_id',
          name: 'student',
          beforeEnter: (to, from, next) => {
            getPage('Student Profile')
            next()
          },
          component: () => import('../components/pages/student-in-class'),
          meta: { requiresAuth: true },
        },
        {
          path: '/student-marks',
          name: 'student-marks',
          beforeEnter: (to, from, next) => {
            getPage('Student Marks')
            next()
          },
          component: () => import('../components/pages/Marks/viewStudentMarks'),
          meta: { requiresAuth: true },
        },
      ],
    },
  ],
})
function getPage (title) {
  app.state.page = title
}
