// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { ColorThemePlugin } from '../services/vuestic-ui'
import store from '../store/index'
import router from '../router/index'
import { VuesticPlugin } from '../services/vuestic-ui/components'
import '../i18n/index'
import YmapPlugin from 'vue-yandex-maps'
import VueClipboard from 'vue-clipboard2'

import '../metrics'
import '../registerServiceWorker'
import VeeValidate from 'vee-validate'

import { consoleBuildInfo } from 'vue-cli-plugin-build-info/plugin'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import axios from 'axios'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueAxios from 'vue-axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
import 'font-awesome/css/font-awesome.min.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('vx-select', vSelect)
Vue.use(VueFileAgent)
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

consoleBuildInfo()
axios.defaults.baseURL = 'https://api.uhtgl.org/public/'
// axios.defaults.baseURL = 'http://localhost:8081/qollege-v-1-api/public'
Vue.use(VeeValidate)

Vue.use(VuesticPlugin)
Vue.use(YmapPlugin)
Vue.use(VueClipboard)

Vue.use(ColorThemePlugin, {
  // override colors here.
})
// let logger = ''
// if (localStorage.getItem('logged_user')) { logger = JSON.parse(localStorage.getItem('logged_user')) }
// router.beforeEach((to, from, next) => {
//   // if (localStorage.getItem('logged_user')) { logger = JSON.parse(localStorage.getItem('logged_user')) }
//   // store.commit('setLoading', true)
//   // next()
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     alert(to.fullPath)
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (!logger.accessToken) {
//       // localStorage.setItem('pathToLoadAfterLogin', to.fullPath)
//       next({
//         path: { name: 'login' },
//         query: { redirect: window.location.pathname },
//       })
//     } else {
//       next()
//     }
//   } else {
//     next() // make sure to always call next()!
//   }
// })
router.afterEach((to, from) => {
  store.commit('setLoading', false)
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  vuetify,
  store,
  render: h => h(App),
})
