// alert('does it work?')
let dash = []
// let user = []
const user = localStorage.getItem('logged_user')
// user = this.$store.state.user_status
if (user != null) {
  dash = JSON.parse(user)
}
let navigationRoutes = []
if (dash.type === '0') {
  navigationRoutes = {
    root: {
      name: '/',
      displayName: 'navigationRoutes.home',
    },
    routes: [
      {
        name: 'dashboard',
        displayName: 'menu.dashboard',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'intakes',
        displayName: 'Intakes',
        meta: {
          iconClass: 'iconicstroke iconicstroke-info',
        },
      },
      {
        name: 'students',
        displayName: 'Students',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
        children: [
          {
            name: 'view-students',
            displayName: 'View Students',
            meta: {
              iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
            },
          },
        ],
      },
      {
        name: 'faculty',
        displayName: 'Faculty',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-tables',
        },
      },
      {
        name: 'department',
        displayName: 'Department',
        meta: {
          iconClass: 'glyphicon glyphicon-glass',
        },
      },
      {
        name: 'levels',
        displayName: 'Levels',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'class',
        displayName: 'Classes',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'communication',
        displayName: 'Communication',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'course',
        displayName: 'Courses',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-ui-elements',
        },
        disabled: true,
        children: [
          {
            name: 'courses',
            displayName: 'Manage Course',
            meta: {
              iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
            },
          },
          {
            name: 'course-records',
            displayName: 'Course Records',
            meta: {
              iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
            },
          },
        ],
      },
      {
        name: 'marks',
        displayName: 'Marks',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'staff',
        displayName: 'Staff',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'posts',
        displayName: 'Posts',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'studentCard',
        displayName: 'Student card',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-tables',
        },
      },
      {
        name: 'document-setup',
        displayName: 'Document Setup',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
    ],
  }
} else if (dash.type === '1') {
  navigationRoutes = {
    root: {
      name: '/',
      displayName: 'navigationRoutes.home',
    },
    routes: [
      {
        name: 'dashboard',
        displayName: 'menu.dashboard',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'registration',
        displayName: 'Registration',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
      {
        name: 'student-marks',
        displayName: 'My marks',
        meta: {
          iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        },
      },
    ],
  }
}
export {
  navigationRoutes,
}
